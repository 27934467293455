<template>
  <Vcaptcha
    :show="isShow"
    :imgs="puzzleImgList"
    :canvasWidth="450"
    :canvasHeight="320"
    @success="onSuccess"
    @close="onClose"
  />
</template>

<script>
import Vcaptcha from './index.vue';
export default {
  components: {
    Vcaptcha
  },
  data() {
    return {
      visible: false,
      request: {
        receiver: '',
        captcha: '',
        type: ''
      },
      isShow: false,
      puzzleImgList: [
      ]
    };
  },
  methods: {
    show(phone, type) {
      this.request.type = type;
      this.request.receiver = phone;
      this.request.captcha = '';
      this.isShow = true;
    },
    // 滑动成功
    async onSuccess() {
      await this.getMachineCode();
      await this.sendCode();
    },
    onClose() {
      this.isShow = false;
    },
    // 获取机器码
    async getMachineCode() {
      const res = await this.$api.getMachineCode({
        phone: this.request.receiver
      });
      if (res.code == 0) {
        this.request.captcha = res.data;
      }
    },
    // 发送验证码
    async sendCode() {
      const res = await this.$api.sendCode(this.request);
      if (res.code == 0) {
        notify(this.$t('captcha.send'));
        this.$emit('success');
      } else {
        notify(res.message, 'warning');
      }
      this.isShow = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
